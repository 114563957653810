import React from "react"
import RacePageOneDay2022 from "../components/racepageOneDay2022"

const Race2022 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Gent - Wevelgem In Flanders Fields",
      edition: 2022,
      what: "Race data",
      updated: "2022-03-26 17:32:19",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21872: {
        teamId: "21872",
        teamUciCode: "FDJ",
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        teamNationCode: "FRA",
      },
      21877: {
        teamId: "21877",
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "NED",
      },
      21881: {
        teamId: "21881",
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
      },
      22011: {
        teamId: "22011",
        teamUciCode: "SDW",
        teamName: "Team SD Worx",
        teamNationCode: "NED",
      },
      22296: {
        teamId: "22296",
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
      },
      22809: {
        teamId: "22809",
        teamUciCode: "BEX",
        teamName: "Team BikeExchange-Jayco",
        teamNationCode: "AUS",
      },
      23616: {
        teamId: "23616",
        teamUciCode: "LSL",
        teamName: "Lotto Soudal Ladies",
        teamNationCode: "BEL",
      },
      23744: {
        teamId: "23744",
        teamUciCode: "CSR",
        teamName: "CANYON//SRAM Racing",
        teamNationCode: "GER",
      },
      23811: {
        teamId: "23811",
        teamUciCode: "WNT",
        teamName: "Ceratizit-WNT Pro Cycling",
        teamNationCode: "GER",
      },
      24281: {
        teamId: "24281",
        teamUciCode: "AGI",
        teamName: "AG Insurance-NXTG Team",
        teamNationCode: "NED",
      },
      24283: {
        teamId: "24283",
        teamUciCode: "PHV",
        teamName: "Parkhotel Valkenburg",
        teamNationCode: "NED",
      },
      27553: {
        teamId: "27553",
        teamUciCode: "UXT",
        teamName: "Uno-X Pro Cycling Team",
        teamNationCode: "NOR",
      },
      27874: {
        teamId: "27874",
        teamUciCode: "LIV",
        teamName: "Liv Racing Xstra",
        teamNationCode: "NED",
      },
      27895: {
        teamId: "27895",
        teamUciCode: "JVW",
        teamName: "Team Jumbo-Visma",
        teamNationCode: "NED",
      },
      27926: {
        teamId: "27926",
        teamUciCode: "TIB",
        teamName: "EF Education-TIBCO-SVB",
        teamNationCode: "USA",
      },
      27951: {
        teamId: "27951",
        teamUciCode: "LEW",
        teamName: "Le Col Wahoo",
        teamNationCode: "GBR",
      },
      28095: {
        teamId: "28095",
        teamUciCode: "COF",
        teamName: "Cofidis Women Team",
        teamNationCode: "FRA",
      },
      28260: {
        teamId: "28260",
        teamUciCode: "HPW",
        teamName: "Human Powered Health",
        teamNationCode: "USA",
      },
      28433: {
        teamId: "28433",
        teamUciCode: "CGS",
        teamName: "Roland Cogeas Edelweiss Squad",
        teamNationCode: "SUI",
      },
      28537: {
        teamId: "28537",
        teamUciCode: "PLP",
        teamName: "Plantur-Pura",
        teamNationCode: "BEL",
      },
      28572: {
        teamId: "28572",
        teamUciCode: "BCV",
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        teamNationCode: "BEL",
      },
      28573: {
        teamId: "28573",
        teamUciCode: "MUL",
        teamName: "Multum Accountants Ladies Cycling Team",
        teamNationCode: "BEL",
      },
      28954: {
        teamId: "28954",
        teamUciCode: "VAL",
        teamName: "Valcar-Travel & Service",
        teamNationCode: "ITA",
      },
      28991: {
        teamId: "28991",
        teamUciCode: "UAD",
        teamName: "UAE Team ADQ",
        teamNationCode: "UAE",
      },
    },
    riders: {
      99103: {
        id: 99103,
        startno: 212,
        firstName: "Elisa",
        lastName: "Serne",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-09-22",
        teamId: 28573,
        teamName: "Multum Accountants Ladies Cycling Team",
        stillInTheRace: "Y",
      },
      96695: {
        id: 96695,
        startno: 31,
        firstName: "Shari",
        lastName: "Bossuyt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2000-09-05",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      114479: {
        id: 114479,
        startno: 91,
        firstName: "Hannah",
        lastName: "Buch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2002-09-11",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      96693: {
        id: 96693,
        startno: 15,
        firstName: "Lonneke",
        lastName: "Uneken",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2000-03-02",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      89419: {
        id: 89419,
        startno: 35,
        firstName: "Katarzyna",
        lastName: "Niewiadoma",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1994-09-29",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      90063: {
        id: 90063,
        startno: 46,
        firstName: "Sara",
        lastName: "Poidevin",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1996-05-07",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      89537: {
        id: 89537,
        startno: 12,
        firstName: "Elena",
        lastName: "Cecchini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-05-25",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      93404: {
        id: 93404,
        startno: 76,
        firstName: "Amber",
        lastName: "Van Der Hulst",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-09-21",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      89523: {
        id: 89523,
        startno: 124,
        firstName: "Eugenia",
        lastName: "Bujak",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1989-06-25",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      89446: {
        id: 89446,
        startno: 24,
        firstName: "Chloe",
        lastName: "Hosking",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1990-10-01",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      155220: {
        id: 155220,
        startno: 134,
        firstName: "Rebecca",
        lastName: "Koerner",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "2000-09-22",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      89471: {
        id: 89471,
        startno: 104,
        firstName: "Arianna",
        lastName: "Fidanza",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-01-06",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      99107: {
        id: 99107,
        startno: 156,
        firstName: "Anne",
        lastName: "Van Rooijen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2000-06-04",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      94108: {
        id: 94108,
        startno: 214,
        firstName: "Mareille",
        lastName: "Meijering",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-03-11",
        teamId: 28573,
        teamName: "Multum Accountants Ladies Cycling Team",
        stillInTheRace: "Y",
      },
      98831: {
        id: 98831,
        startno: 102,
        firstName: "Teniel",
        lastName: "Campbell",
        nationCode: "TRI",
        nationName: "Trinidad & Tobago",
        birthDate: "1997-09-23",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      89431: {
        id: 89431,
        startno: 185,
        firstName: "Rachel",
        lastName: "Neylan",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1982-03-09",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
      },
      98849: {
        id: 98849,
        startno: 184,
        firstName: "Valentine",
        lastName: "Fortin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-04-24",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
      },
      90889: {
        id: 90889,
        startno: 163,
        firstName: "Chiara",
        lastName: "Consonni",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-05-22",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      90013: {
        id: 90013,
        startno: 72,
        firstName: "Eva",
        lastName: "Buurman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1994-09-07",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      89806: {
        id: 89806,
        startno: 211,
        firstName: "Fien",
        lastName: "Delbaere",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-04-21",
        teamId: 28573,
        teamName: "Multum Accountants Ladies Cycling Team",
        stillInTheRace: "Y",
      },
      96817: {
        id: 96817,
        startno: 113,
        firstName: "Pfeiffer",
        lastName: "Georgi",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2000-09-27",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      89410: {
        id: 89410,
        startno: 14,
        firstName: "Christine",
        lastName: "Majerus",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1987-02-25",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      92142: {
        id: 92142,
        startno: 52,
        firstName: "Stine",
        lastName: "Borgli",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1990-07-04",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      92988: {
        id: 92988,
        startno: 221,
        firstName: "Lone",
        lastName: "Meertens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-04-16",
        teamId: 24281,
        teamName: "AG Insurance-NXTG Team",
        stillInTheRace: "Y",
      },
      105104: {
        id: 105104,
        startno: 141,
        firstName: "Franziska",
        lastName: "Brau\u00c3\u009fe",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1998-09-20",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      90014: {
        id: 90014,
        startno: 61,
        firstName: "Nina",
        lastName: "Buijsman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-11-16",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      91079: {
        id: 91079,
        startno: 205,
        firstName: "Maria",
        lastName: "Martins",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1999-07-09",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
      },
      125389: {
        id: 125389,
        startno: 191,
        firstName: "Kristyna",
        lastName: "Burlova",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "2002-03-25",
        teamId: 23616,
        teamName: "Lotto Soudal Ladies",
        stillInTheRace: "Y",
      },
      89448: {
        id: 89448,
        startno: 36,
        firstName: "Soraya",
        lastName: "Paladin",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-05-04",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      89827: {
        id: 89827,
        startno: 116,
        firstName: "Floortje",
        lastName: "Mackaij",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-10-18",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      93494: {
        id: 93494,
        startno: 175,
        firstName: "Claudia",
        lastName: "Jongerius",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-12-02",
        teamId: 28572,
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        stillInTheRace: "Y",
      },
      150247: {
        id: 150247,
        startno: 125,
        firstName: "Linda",
        lastName: "Zanetti",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "2002-03-10",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      89426: {
        id: 89426,
        startno: 101,
        firstName: "Georgia",
        lastName: "Baker",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-09-21",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      97841: {
        id: 97841,
        startno: 54,
        firstName: "Clara",
        lastName: "Copponi",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-01-12",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      89454: {
        id: 89454,
        startno: 126,
        firstName: "Anna",
        lastName: "Trevisi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1992-05-08",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      114498: {
        id: 114498,
        startno: 225,
        firstName: "Ilse",
        lastName: "Pluimers",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2002-04-29",
        teamId: 24281,
        teamName: "AG Insurance-NXTG Team",
        stillInTheRace: "Y",
      },
      126302: {
        id: 126302,
        startno: 45,
        firstName: "Clara",
        lastName: "Honsinger",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1997-06-05",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      125562: {
        id: 125562,
        startno: 173,
        firstName: "Naomi",
        lastName: "De Roeck",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-10-23",
        teamId: 28572,
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        stillInTheRace: "Y",
      },
      89637: {
        id: 89637,
        startno: 32,
        firstName: "Alice",
        lastName: "Barnes",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1995-07-17",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      90862: {
        id: 90862,
        startno: 181,
        firstName: "Martina",
        lastName: "Alzini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-02-10",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
      },
      114500: {
        id: 114500,
        startno: 155,
        firstName: "Sofie",
        lastName: "Van Rooijen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2002-07-13",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      89886: {
        id: 89886,
        startno: 236,
        firstName: "Julie",
        lastName: "Van De Velde",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-06-02",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      89839: {
        id: 89839,
        startno: 44,
        firstName: "Kathrin",
        lastName: "Hammes",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1989-01-09",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      89413: {
        id: 89413,
        startno: 6,
        firstName: "Jip",
        lastName: "van den Bos",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-04-12",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      89819: {
        id: 89819,
        startno: 34,
        firstName: "Mikayla",
        lastName: "Harvey",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1998-09-07",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      89488: {
        id: 89488,
        startno: 73,
        firstName: "Alison",
        lastName: "Jackson",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1988-12-14",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      93088: {
        id: 93088,
        startno: 235,
        firstName: "Laura",
        lastName: "S\u00c3\u00bc\u00c3\u009femilch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1997-02-23",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      96753: {
        id: 96753,
        startno: 74,
        firstName: "Silke",
        lastName: "Smulders",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-04-01",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      97812: {
        id: 97812,
        startno: 94,
        firstName: "Diana",
        lastName: "Klimova",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1996-10-08",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      89708: {
        id: 89708,
        startno: 23,
        firstName: "Lauretta",
        lastName: "Hanson",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-10-29",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      89850: {
        id: 89850,
        startno: 186,
        firstName: "Pernille",
        lastName: "Mathiesen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1997-10-05",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
      },
      89541: {
        id: 89541,
        startno: 84,
        firstName: "Barbara",
        lastName: "Guarischi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-10-02",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      96741: {
        id: 96741,
        startno: 152,
        firstName: "Femke",
        lastName: "Gerritse",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-05-14",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      96709: {
        id: 96709,
        startno: 204,
        firstName: "Maike",
        lastName: "van der Duin",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-09-12",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
      },
      96702: {
        id: 96702,
        startno: 132,
        firstName: "Wilma",
        lastName: "Olausson",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "2001-04-09",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      89425: {
        id: 89425,
        startno: 103,
        firstName: "Jessica",
        lastName: "Allen",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1993-04-17",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      96692: {
        id: 96692,
        startno: 182,
        firstName: "Victoire",
        lastName: "Berteau",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2000-08-16",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
      },
      89983: {
        id: 89983,
        startno: 161,
        firstName: "Alice Maria",
        lastName: "Arzuffi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-11-19",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      114476: {
        id: 114476,
        startno: 22,
        firstName: "Shirin",
        lastName: "van Anrooij",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2002-02-05",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      178345: {
        id: 178345,
        startno: 62,
        firstName: "Kaia",
        lastName: "Schmid",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2003-01-07",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      114772: {
        id: 114772,
        startno: 193,
        firstName: "Katrijn",
        lastName: "De Clercq",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2002-02-05",
        teamId: 23616,
        teamName: "Lotto Soudal Ladies",
        stillInTheRace: "Y",
      },
      114473: {
        id: 114473,
        startno: 202,
        firstName: "Eluned",
        lastName: "King",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2002-08-01",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
      },
      57567: {
        id: 57567,
        startno: 64,
        firstName: "Eri",
        lastName: "Yonamine",
        nationCode: "JPN",
        nationName: "Japan",
        birthDate: "1991-04-25",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      156832: {
        id: 156832,
        startno: 215,
        firstName: "Willemijn",
        lastName: "Prins",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2003-08-16",
        teamId: 28573,
        teamName: "Multum Accountants Ladies Cycling Team",
        stillInTheRace: "Y",
      },
      114802: {
        id: 114802,
        startno: 194,
        firstName: "Eefje",
        lastName: "Brandt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2002-05-22",
        teamId: 23616,
        teamName: "Lotto Soudal Ladies",
        stillInTheRace: "Y",
      },
      96725: {
        id: 96725,
        startno: 135,
        firstName: "Hannah",
        lastName: "Ludwig",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2000-02-15",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      89832: {
        id: 89832,
        startno: 26,
        firstName: "Ellen",
        lastName: "Van Dijk",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1987-02-11",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      95291: {
        id: 95291,
        startno: 75,
        firstName: "Marta",
        lastName: "Jaskulska",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "2000-03-25",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      89480: {
        id: 89480,
        startno: 86,
        firstName: "Arlenis",
        lastName: "Sierra",
        nationCode: "CUB",
        nationName: "Cuba",
        birthDate: "1992-12-07",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      89745: {
        id: 89745,
        startno: 25,
        firstName: "Elisa",
        lastName: "Longo Borghini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1991-12-10",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      93400: {
        id: 93400,
        startno: 114,
        firstName: "Charlotte",
        lastName: "Kool",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-05-06",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      93393: {
        id: 93393,
        startno: 172,
        firstName: "Danique",
        lastName: "Braam",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-09-05",
        teamId: 28572,
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        stillInTheRace: "Y",
      },
      92485: {
        id: 92485,
        startno: 13,
        firstName: "Marlen",
        lastName: "Reusser",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1991-09-20",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      91184: {
        id: 91184,
        startno: 51,
        firstName: "Grace",
        lastName: "Brown",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1992-07-07",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      89514: {
        id: 89514,
        startno: 85,
        firstName: "Lourdes",
        lastName: "Oyarbide",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-04-08",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      89470: {
        id: 89470,
        startno: 122,
        firstName: "Sofia",
        lastName: "Bertizzolo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-08-21",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      93399: {
        id: 93399,
        startno: 223,
        firstName: "Mylene",
        lastName: "De Zoete",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-01-03",
        teamId: 24281,
        teamName: "AG Insurance-NXTG Team",
        stillInTheRace: "Y",
      },
      89828: {
        id: 89828,
        startno: 5,
        firstName: "Coryn",
        lastName: "Labecki",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1992-08-26",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      89807: {
        id: 89807,
        startno: 71,
        firstName: "Valerie",
        lastName: "Demey",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-01-17",
        teamId: 27874,
        teamName: "Liv Racing Xstra",
        stillInTheRace: "Y",
      },
      93655: {
        id: 93655,
        startno: 2,
        firstName: "Anna",
        lastName: "Henderson",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1998-11-14",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      89719: {
        id: 89719,
        startno: 166,
        firstName: "Silvia",
        lastName: "Persico",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-07-25",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      90115: {
        id: 90115,
        startno: 154,
        firstName: "Belle",
        lastName: "De Gast",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1991-02-04",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      164720: {
        id: 164720,
        startno: 234,
        firstName: "Justine",
        lastName: "Ghekiere",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-05-14",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      90101: {
        id: 90101,
        startno: 43,
        firstName: "Tanja",
        lastName: "Erath",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1989-10-07",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      89420: {
        id: 89420,
        startno: 4,
        firstName: "Riejanne",
        lastName: "Markus",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1994-09-01",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      93003: {
        id: 93003,
        startno: 136,
        firstName: "Mie Bj\u00c3\u00b8rndal",
        lastName: "Ottestad",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1997-07-17",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      167164: {
        id: 167164,
        startno: 195,
        firstName: "Sterre",
        lastName: "Vervloet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2003-11-26",
        teamId: 23616,
        teamName: "Lotto Soudal Ladies",
        stillInTheRace: "Y",
      },
      90127: {
        id: 90127,
        startno: 146,
        firstName: "Lea Lin",
        lastName: "Teutenberg",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1999-07-02",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      90117: {
        id: 90117,
        startno: 65,
        firstName: "Marit",
        lastName: "Raaijmakers",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-06-02",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      154244: {
        id: 154244,
        startno: 93,
        firstName: "Gulnaz",
        lastName: "Khatuntseva",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1994-04-21",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      89743: {
        id: 89743,
        startno: 133,
        firstName: "Julie",
        lastName: "Leth",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1992-07-13",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      89716: {
        id: 89716,
        startno: 53,
        firstName: "Marta",
        lastName: "Cavalli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-03-18",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      96726: {
        id: 96726,
        startno: 151,
        firstName: "Mischa",
        lastName: "Bredewold",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2000-06-20",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      90112: {
        id: 90112,
        startno: 203,
        firstName: "Marjolein",
        lastName: "Van 't Geloof",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-03-27",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
      },
      96746: {
        id: 96746,
        startno: 222,
        firstName: "Julia",
        lastName: "Borgstr\u00c3\u00b6m",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "2001-06-09",
        teamId: 24281,
        teamName: "AG Insurance-NXTG Team",
        stillInTheRace: "Y",
      },
      112278: {
        id: 112278,
        startno: 106,
        firstName: "Ruby",
        lastName: "Roseman-Gannon",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-11-08",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      89917: {
        id: 89917,
        startno: 82,
        firstName: "Aude",
        lastName: "Biannic",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-03-27",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      89484: {
        id: 89484,
        startno: 143,
        firstName: "Lara",
        lastName: "Vieceli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-07-16",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      89423: {
        id: 89423,
        startno: 232,
        firstName: "Yara",
        lastName: "Kastelijn",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-08-09",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      114495: {
        id: 114495,
        startno: 112,
        firstName: "Megan",
        lastName: "Jastrab",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2002-01-29",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      89966: {
        id: 89966,
        startno: 192,
        firstName: "Mieke",
        lastName: "Docx",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-06-08",
        teamId: 23616,
        teamName: "Lotto Soudal Ladies",
        stillInTheRace: "Y",
      },
      89641: {
        id: 89641,
        startno: 201,
        firstName: "Elizabeth",
        lastName: "Holden",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1997-09-12",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
      },
      89497: {
        id: 89497,
        startno: 165,
        firstName: "Ilaria",
        lastName: "Sanguineti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-04-15",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      95824: {
        id: 95824,
        startno: 213,
        firstName: "Celine",
        lastName: "Van Houtum",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-03-12",
        teamId: 28573,
        teamName: "Multum Accountants Ladies Cycling Team",
        stillInTheRace: "Y",
      },
      101796: {
        id: 101796,
        startno: 231,
        firstName: "Sanne",
        lastName: "Cant",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-10-08",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      89826: {
        id: 89826,
        startno: 115,
        firstName: "Liane",
        lastName: "Lippert",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1998-01-13",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      89405: {
        id: 89405,
        startno: 16,
        firstName: "Chantal",
        lastName: "van den Broek-Blaak",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1989-10-22",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      92703: {
        id: 92703,
        startno: 144,
        firstName: "Marta",
        lastName: "Lach",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1997-05-26",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      97208: {
        id: 97208,
        startno: 162,
        firstName: "Olivia",
        lastName: "Baril",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1997-10-10",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      89714: {
        id: 89714,
        startno: 21,
        firstName: "Elisa",
        lastName: "Balsamo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-02-27",
        teamId: 21881,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      90120: {
        id: 90120,
        startno: 111,
        firstName: "Lorena",
        lastName: "Wiebes",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-03-17",
        teamId: 21877,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      186806: {
        id: 186806,
        startno: 96,
        firstName: "L\u00c3\u00a9a",
        lastName: "Stern",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "2001-09-25",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      89538: {
        id: 89538,
        startno: 33,
        firstName: "Tiffany",
        lastName: "Cromwell",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1988-07-06",
        teamId: 23744,
        teamName: "CANYON//SRAM Racing",
        stillInTheRace: "Y",
      },
      89866: {
        id: 89866,
        startno: 83,
        firstName: "Alicia",
        lastName: "Gonzalez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-05-27",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      89443: {
        id: 89443,
        startno: 121,
        firstName: "Marta",
        lastName: "Bastianelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-04-30",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      94113: {
        id: 94113,
        startno: 171,
        firstName: "Minke",
        lastName: "Bakker",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-03-11",
        teamId: 28572,
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        stillInTheRace: "Y",
      },
      93396: {
        id: 93396,
        startno: 216,
        firstName: "Bente",
        lastName: "Van Teeseling",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-04-27",
        teamId: 28573,
        teamName: "Multum Accountants Ladies Cycling Team",
        stillInTheRace: "Y",
      },
      98961: {
        id: 98961,
        startno: 176,
        firstName: "Olha",
        lastName: "Kulynych",
        nationCode: "UKR",
        nationName: "Ukraine",
        birthDate: "2000-02-01",
        teamId: 28572,
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        stillInTheRace: "Y",
      },
      89883: {
        id: 89883,
        startno: 11,
        firstName: "Lotte",
        lastName: "Kopecky",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-11-10",
        teamId: 22011,
        teamName: "Team SD Worx",
        stillInTheRace: "Y",
      },
      100561: {
        id: 100561,
        startno: 206,
        firstName: "Gladys",
        lastName: "Verhulst",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-01-02",
        teamId: 27951,
        teamName: "Le Col Wahoo",
        stillInTheRace: "Y",
      },
      114539: {
        id: 114539,
        startno: 164,
        firstName: "Eleonora Camilla",
        lastName: "Gasparrini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2002-03-25",
        teamId: 28954,
        teamName: "Valcar-Travel & Service",
        stillInTheRace: "Y",
      },
      95821: {
        id: 95821,
        startno: 153,
        firstName: "Femke",
        lastName: "Markus",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-11-17",
        teamId: 24283,
        teamName: "Parkhotel Valkenburg",
        stillInTheRace: "Y",
      },
      114776: {
        id: 114776,
        startno: 233,
        firstName: "Julie",
        lastName: "De Wilde",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2002-12-08",
        teamId: 28537,
        teamName: "Plantur-Pura",
        stillInTheRace: "Y",
      },
      89447: {
        id: 89447,
        startno: 3,
        firstName: "Romy",
        lastName: "Kasper",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1988-05-05",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      89416: {
        id: 89416,
        startno: 1,
        firstName: "Marianne",
        lastName: "Vos",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1987-05-13",
        teamId: 27895,
        teamName: "Team Jumbo-Visma",
        stillInTheRace: "Y",
      },
      89951: {
        id: 89951,
        startno: 131,
        firstName: "Susanne",
        lastName: "Andersen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1998-07-23",
        teamId: 27553,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      92985: {
        id: 92985,
        startno: 174,
        firstName: "Caren",
        lastName: "Commissaris",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-04-22",
        teamId: 28572,
        teamName: "Bingoal Casino-Chevalmeire-Van Eyck Sport",
        stillInTheRace: "Y",
      },
      89536: {
        id: 89536,
        startno: 145,
        firstName: "Lisa",
        lastName: "Brennauer",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1988-06-08",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      91011: {
        id: 91011,
        startno: 63,
        firstName: "Evy",
        lastName: "Kuijpers",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-02-15",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      138754: {
        id: 138754,
        startno: 92,
        firstName: "Tamara",
        lastName: "Dronova",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1993-08-13",
        teamId: 28433,
        teamName: "Roland Cogeas Edelweiss Squad",
        stillInTheRace: "Y",
      },
      92093: {
        id: 92093,
        startno: 81,
        firstName: "Emma",
        lastName: "Norsgaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1999-07-26",
        teamId: 22296,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      89776: {
        id: 89776,
        startno: 42,
        firstName: "Letizia",
        lastName: "Borghesi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-10-16",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      89522: {
        id: 89522,
        startno: 123,
        firstName: "Maaike",
        lastName: "Boogaard",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-08-24",
        teamId: 28991,
        teamName: "UAE Team ADQ",
        stillInTheRace: "Y",
      },
      93392: {
        id: 93392,
        startno: 196,
        firstName: "Kylie",
        lastName: "Waterreus",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-03-22",
        teamId: 23616,
        teamName: "Lotto Soudal Ladies",
        stillInTheRace: "Y",
      },
      89739: {
        id: 89739,
        startno: 56,
        firstName: "Emilia",
        lastName: "Fahlin",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "1988-10-24",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      89430: {
        id: 89430,
        startno: 105,
        firstName: "Alexandra",
        lastName: "Manly",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1996-02-28",
        teamId: 22809,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      89921: {
        id: 89921,
        startno: 55,
        firstName: "Eug\u00c3\u00a9nie",
        lastName: "Duval",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-05-03",
        teamId: 21872,
        teamName: "FDJ-Nouvelle Aquitaine-Futuroscope",
        stillInTheRace: "Y",
      },
      89984: {
        id: 89984,
        startno: 142,
        firstName: "Maria Giulia",
        lastName: "Confalonieri",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-03-30",
        teamId: 23811,
        teamName: "Ceratizit-WNT Pro Cycling",
        stillInTheRace: "Y",
      },
      114499: {
        id: 114499,
        startno: 226,
        firstName: "Maud",
        lastName: "Rijnbeek",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2002-12-03",
        teamId: 24281,
        teamName: "AG Insurance-NXTG Team",
        stillInTheRace: "Y",
      },
      90107: {
        id: 90107,
        startno: 66,
        firstName: "Lily",
        lastName: "Williams",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1994-06-24",
        teamId: 28260,
        teamName: "Human Powered Health",
        stillInTheRace: "Y",
      },
      90111: {
        id: 90111,
        startno: 183,
        firstName: "Alana",
        lastName: "Castrique",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-05-08",
        teamId: 28095,
        teamName: "Cofidis Women Team",
        stillInTheRace: "Y",
      },
      91096: {
        id: 91096,
        startno: 41,
        firstName: "Elizabeth",
        lastName: "Banks",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1990-11-07",
        teamId: 27926,
        teamName: "EF Education-TIBCO-SVB",
        stillInTheRace: "Y",
      },
      96873: {
        id: 96873,
        startno: 224,
        firstName: "Gaia",
        lastName: "Masetti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2001-10-26",
        teamId: 24281,
        teamName: "AG Insurance-NXTG Team",
        stillInTheRace: "Y",
      },
    },
  }

  const race = "Gent Wevelgem"
  const raceID = 9048

  return (
    <RacePageOneDay2022
      title={`${race} 2022`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2022
